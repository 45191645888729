<template>
  <div>
    <b-card
      v-if="loading"
    >
      <div
        class="service-locators-loader d-flex justify-content-center"
      >
        <b-spinner label="Loading..." />
      </div>
    </b-card>
    <b-card
      v-else
      class="service-locators-card"
    >
      <b-row>
        <b-col>
          <h4>
            Service Locators List
          </h4>
        </b-col>
        <b-col>
          <b-button
            v-if="$ableTo('Create Service Locator')"
            class="float-right"
            @click="() => $router.push({name: 'service-locators-form'})"
          >
            <feather-icon icon="PlusIcon" />
             Add New
          </b-button>
        </b-col>
      </b-row>
      <b-row>
        <b-col>
          <new-table
            :primary-key="false"
            :export-file-name="exportFileName"
            :columns="columns"
            :items="items"
            router-link="service-locators-form"
            table-name="service-locator-list"
            @delete="Delete"
          />
        </b-col>
      </b-row>
    </b-card>
  </div>
</template>

<script>
import {
  BCard, VBTooltip, BCol, BRow, BSpinner, BButton,
} from 'bootstrap-vue'
import NewTable from '@/views/new-table.vue'
import Moment from 'moment'

export default {
  components: {
    BButton,
    BCard,
    BSpinner,
    BCol,
    BRow,
    NewTable,
  },
  directives: {
    'b-tooltip': VBTooltip,
  },
  data() {
    return {
      loading: false,
      oldFilter: '',
      exportFileName: `service_locators_List_${Moment(new Date()).format('YYYY-MM-DD')}`,
      items: [],
      columns: [
        {
          label: 'Service Locator',
          data: 'name',
        },
        // {
        //   label: 'Rating',
        //   data: 'rating',
        // },
        {
          label: 'Address',
          data: 'address',
        },
        // {
        //   label: 'Phone',
        //   data: 'phone',
        // },
        {
          label: 'Country',
          data: 'country',
        },
        {
          label: 'Actions',
          data: 'actions',
          custom_template: {
            template_name: 'servicelocators.list.actions',
          },
          filterable: false,
          sortable: false,
        },
      ],
    }
  },
  mounted() {
    this.load()
  },
  methods: {
    load(filter = null) {
      this.loading = true
      this.$http.get(this.$resources.serviceLocators.index, {
        params: {
          filter,
          backend: true,
        },
      }).then(response => {
        const { data: { status, data } } = response
        if (status === 200) {
          this.oldFilter = filter
          this.items = data.rows
          this.loading = false
        }
      })
    },
    Delete(item) {
      this.$swal({
        title: 'Delete service locator?',
        text: 'Would you like to delete this service locator?',
        type: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#FF0000',
        confirmButtonText: 'Yes',
      }).then(result => { // <--
        if (result.value) { // <-- if confirmed
          this.$http.delete(this.$resources.serviceLocators.destroy.replace(':id', item.id), {
          }).then(() => {
            this.load()
          })
        }
      })
    },
  },
}
</script>
<style scoped lang="scss">
.service-locators-loader {
  min-height: 900px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.service-locators-card {
  min-height: 900px;
  display: flex;
  justify-content: center;
  align-items: center;
}
</style>
